<template>
    <div class="qwe">
  
        <div>
            <a href="/qflf.mobileconfig" >
                <button class="dedwapp">
                下载配置文件 
            </button>
            </a>
           
        </div>

        <!-- 模块1 -->
        <div class="module">
            <p>1. 点击上方“下载配置文件”下载描述文件。</p>
            <div>
                <img src="../assets/config1.png" width="100%">
            </div>
            <p>2.点击“允许”后，从手机桌面打开“设置”，进入设置后点击“已下载的描述文件”。</p>
            <div>
                <img src="../assets/config2.png" width="100%">
            </div>
            <p>3.点击右上角的“安装”，输入锁屏密码，再次点击右上角的“安装”即可！</p>
            <div>
                <img src="../assets/config3.png" width="100%">
            </div>

        </div> 
    </div>
</template>
  
<style>
 

 
 

 

 


.module {
    margin-top: 10px;
    /* 设置模块之间的垂直边距 */
    width: 100%;
    /* 设置模块宽度为100% */
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* 设置模块边框为黑色 */
    box-sizing: border-box;
    /* 边框宽度不会增加模块宽度 */
    background-color: #fff;
    /* 设置模块背景为透明色 */
    border-radius: 10px;
    /* 添加圆角效果 */
    padding: 10px;

}

.dedwapp {
    margin: 10px;
    color: #000;
    border-color: #e9dde3;
    background: #EC4899;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
    box-shadow: 0 2px #0000000b;
    padding: 10px;
}

.module p {
    text-align: left;
}
</style>
  
<script>
export default {
    // 在这里定义组件的逻辑
}
</script>
  