<template>
  <div class="layout">
    <!-- 顶部标题 -->
    <div style="display: flex;    margin: 10px;" >
      <img src="../assets/logo2.png" width="15%">
        
    </div>
    
    <!-- 下部模块 -->
    <div class="centered">
      <div>
         <img src="../assets/txt21.png" width="100%" >
      </div>
      <div style="margin-top: 20px;">
        <img src="../assets/android2.png" width="60%" @click="downloadApp">
      </div>

      <div style="margin-top: 10px;">
        <img src="../assets/appStore2.png" width="60%" @click="iosTg">
      </div>
  
    </div>
        
  </div>
</template>

<style>
.layout {
  display: flex;
  flex-direction: column;
 
  background-image: url("../assets/banner21.png"); 
  background-size: cover; /* 让背景图片充满容器 */
  background-position: center; /* 将背景图片居中 */
  height: 99vh; /* 使容器高度等于视口高度，确保背景铺满整个屏幕 */
 
}
.centered {
  max-width: 100%;
    max-height: 100vh;
    margin: auto;
}
  
</style>
  
  <script>
  import { ref } from 'vue'
  import router from '@/router/index'
  import { useRoute } from 'vue-router' 
  export default {
    name: 'indexView',
    setup() {
   
  
      const route = useRoute()
  
      const key = route.query.code;
  
      const iosTg = () => {
        router.push({ path: '/ios' })
      }
  
      const downloadApp = () => {
        if (key) {
          window.location.href = "/qflf.apk?code=" + key
        } else {
          window.location.href = "/qflf.apk"
        }
      }
    
      return {
        iosTg,
        downloadApp,
      
      }
    }
  
  
  
  }
  </script>